/* Table of Contents
/* ------------------------------------------------------------

This is a development CSS file which is built to a minified
production stylesheet in assets/built/screen.css

1.  Global Styles
2.  Layout
3.  Special Templates
4.  Site Header
5.  Site Navigation
6.  Post Feed
7.  Single Post
  7.1. Subscribe Form
  7.2. Post Footer
  7.3. Comments
  7.4. Related Posts
  7.5. Floating Header
8.  Author Template
9.  Error Template
10. Subscribe Overlay
11. Site Footer

*/

/* 1. Global - Set up the things
/* ---------------------------------------------------------- */
@import "global.css";

body {
  background: #f4f8fb;
}

.img {
  display: block;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  border-radius: 100%;
}

.hidden {
  visibility: hidden;
  position: absolute;
  text-indent: -9999px;
}

/* 2. Layout - Page building blocks
/* ---------------------------------------------------------- */

.site-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site-main {
  z-index: 100;
  flex-grow: 1;
}

/* Full width page blocks */
.outer {
  position: relative;
  padding: 0 4vw;
}

/* Centered content container blocks */
.inner {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}

/* Usage:

<div class="outer">
    <div class="inner">
        Centered content
    </div>
</div>

*/

/* 3. Special Template Styles
/* ---------------------------------------------------------- */

@media (min-width: 900px) {
  .home-template .post-feed,
  .tag-template .post-feed,
  .author-template .post-feed {
    margin-top: -70px;
    padding-top: 0;
  }

  .home-template .site-nav {
    position: relative;
    top: -70px;
  }
}

/* 4. Site Header
/* ---------------------------------------------------------- */

.site-header {
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #fff;
  background: black;
  background-size: cover;
}

.site-header:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: block;
  background: rgba(0, 0, 0, 0.18);
}

.site-header:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  z-index: 10;
  display: block;
  height: 80px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
}

.site-header.no-cover:before,
.site-header.no-cover:after {
  display: none;
}

.site-header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 9vw 4vw;
  min-height: 200px;
  max-height: 450px;
  text-align: center;
}

.site-title {
  z-index: 10;
  margin: 0;
  padding: 0;
  font-size: 3.8rem;
  font-weight: 700;
}

.site-logo {
  max-height: 45px;
}

.site-description {
  z-index: 10;
  margin: 1em 0 0;
  padding: 5px 0;
  font-size: 2.2rem;
  font-weight: 300;
  letter-spacing: 0.5px;
  opacity: 0.8;
}

@media (max-width: 500px) {
  .site-title {
    font-size: 3rem;
  }

  .site-description {
    font-size: 1.8rem;
  }
}

/* 5. Site Navigation
/* ---------------------------------------------------------- */

.site-nav {
  position: relative;
  z-index: 300;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* overflow-y: hidden; */
  height: 40px;
  font-size: 1.2rem;
}

.site-nav-left {
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin-right: 10px;
  padding-bottom: 80px;
  letter-spacing: 0.4px;
  white-space: nowrap;

  -ms-overflow-scrolling: touch;
}

/* Site Nav Hack Explanation (above):

What's happening above it .site-nav-left is set to overflow-x and allow sideways scrolling, so that when there isn't enough space for all nav items (either due to lots of nav items, or a small viewport), you can still scroll side-to-side to reach them.

The knock-on effect of this is ugly browser-scroll bars at the bottom, so 80px of padding-bottom and a 40px fixed height parent (.site-nav) hides that entirely. Slightly hacky code. But nice clean end-result.

*/

.site-nav-logo {
  flex-shrink: 0;
  display: block;
  margin-right: 24px;
  color: #fff;
  font-size: 1.7rem;
  line-height: 1em;
  font-weight: bold;
  letter-spacing: -0.5px;
}

.site-nav-logo:hover {
  text-decoration: none;
}

.site-nav-logo img {
  display: block;
  width: auto;
  height: 30px;
}

.nav {
  display: flex;
  margin: 0 0 0 -12px;
  padding: 0;
  list-style: none;
}

.nav li {
  display: block;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

.nav li a {
  display: block;
  margin: 0;
  padding: 10px 12px;
  color: #fff;
  opacity: 0.8;
}

.site-nav-right .btn-call-to-act {
  font-weight: 700;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 2px;
  color: #fff;
  background: #145cf2;
  margin-right: 2em;
  padding: 0.6em 1.2em;
  display: inline-block;
  text-transform: uppercase;
}

.site-nav-right .btn-call-to-act:hover {
  text-decoration: none;
  background: #138ed6;
}

.nav li a:hover {
  text-decoration: none;
  opacity: 1;
}

.site-nav-right {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 40px;
}

.social-links {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.social-links a:last-of-type {
  padding-right: 20px;
}

.social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 10px;
  color: #fff;
  opacity: 0.8;
}

.social-link:hover {
  opacity: 1;
}

.social-link svg {
  height: 1.8rem;
  fill: #fff;
}

.social-link-fb svg {
  height: 1.5rem;
}

.social-link-wb svg {
  height: 1.6rem;
}

.social-link-wb svg path {
  stroke: #fff;
}

.social-link-rss svg {
  height: 1.9rem;
}

.subscribe-button {
  display: block;
  padding: 4px 10px;
  border: #fff 1px solid;
  color: #fff;
  font-size: 1.2rem;
  line-height: 1em;
  border-radius: 10px;
  opacity: 0.8;
}

.subscribe-button:hover {
  text-decoration: none;
  opacity: 1;
}

.rss-button {
  opacity: 0.8;
}

.rss-button:hover {
  opacity: 1;
}

.rss-button svg {
  margin-bottom: 1px;
  height: 2.1rem;
  fill: #fff;
}

@media (max-width: 700px) {
  .site-header {
    padding-right: 0;
    padding-left: 0;
  }

  .site-nav-left {
    margin-right: 0;
    padding-left: 4vw;
  }

  .site-nav-right {
    display: none;
  }
}

/* 6. Post Feed
/* ---------------------------------------------------------- */

.post-feed {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  padding: 40px 0 0 0;
}

.post-card {
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 20px 40px;
  min-height: 300px;
  background: #fff center center;
  background-size: cover;
  border-radius: 5px;
  box-shadow:
    rgba(39, 44, 49, 0.06) 8px 14px 38px,
    rgba(39, 44, 49, 0.03) 1px 3px 8px;
  transition: all 0.5s ease;
}

.post-card:hover {
  box-shadow:
    0 0 1px rgba(39, 44, 49, 0.1),
    0 3px 16px rgba(39, 44, 49, 0.07);
  transition: all 0.3s ease;
  transform: translate3D(0, -1px, 0);
}

.post-card-image-link {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}

.post-card-image {
  width: auto;
  height: 200px;
  background: var(--lightgrey) no-repeat center center;
  background-size: cover;
}

.post-card-image-tag {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 400px;
}

.post-card-content-link {
  position: relative;
  display: block;
  padding: 25px 25px 0;
  color: var(--darkgrey);
}

.post-card-content-link:hover {
  text-decoration: none;
}

.post-card-tags {
  display: block;
  margin-bottom: 4px;
  color: var(--midgrey);
  font-size: 1.2rem;
  line-height: 1.15em;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.post-card-title {
  margin-top: 0;
}

.post-card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.post-card-excerpt {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.post-card-meta {
  padding: 0 25px 25px;
}

.author-profile-image {
  margin-right: 5px;
  width: 25px;
  height: 25px;
  border-radius: 100%;

  object-fit: cover;
}

.post-card-author {
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.reading-time {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 33px;
  flex-shrink: 0;
  margin-left: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  float: right;
  margin-top: -4px;
  color: var(--midgrey);
}

/* Special Styling for home page grid (below):

The first (most recent) post in the list is styled to be bigger than the others and take over the full width of the grid to give it more emphasis. Wrapped in a media query to make sure this only happens on large viewports / desktop-ish devices.

 */

@media (min-width: 795px) {
  .home-template .post-feed .post-card:nth-child(6n + 1):not(.no-image) {
    flex: 1 1 100%;
    flex-direction: row;
  }

  .home-template
    .post-feed
    .post-card:nth-child(6n + 1):not(.no-image)
    .post-card-image-link {
    position: relative;
    flex: 1 1 auto;
    border-radius: 5px 0 0 5px;
  }

  .home-template
    .post-feed
    .post-card:nth-child(6n + 1):not(.no-image)
    .post-card-image {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .home-template
    .post-feed
    .post-card:nth-child(6n + 1):not(.no-image)
    .post-card-content {
    flex: 0 1 357px;
  }

  .home-template .post-feed .post-card:nth-child(6n + 1):not(.no-image) h2 {
    font-size: 2.6rem;
  }

  .home-template .post-feed .post-card:nth-child(6n + 1):not(.no-image) p {
    font-size: 1.8rem;
    line-height: 1.55em;
  }

  .home-template
    .post-feed
    .post-card:nth-child(6n + 1):not(.no-image)
    .post-card-content-link {
    padding: 30px 40px 0;
  }

  .home-template
    .post-feed
    .post-card:nth-child(6n + 1):not(.no-image)
    .post-card-meta {
    padding: 0 40px 30px;
  }
}

.home-template .site-header:after {
  display: none;
}

/* Adjust some margins for smaller screens */
@media (max-width: 650px) {
  .post-feed {
    padding-top: 5vw;
  }

  .post-card {
    margin: 0 20px 5vw;
  }
}

/* 7. Single Post
/* ---------------------------------------------------------- */

.post-template .site-main,
.page-template .site-main {
  padding-bottom: 4vw;
  background: #fff;
}

.post-full {
  position: relative;
  z-index: 50;
  margin: 0 auto;
}

/* ^ Required to make .post-full-content:before/after z-index stacking work */

.post-full-header {
  margin: 0 auto;
  padding: 6vw 3vw 3vw;
  max-width: 1040px;
  text-align: center;
}

@media (max-width: 500px) {
  .post-full-header {
    padding: 14vw 3vw 10vw;
  }
}

.post-full-meta {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--midgrey);
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
}

.post-full-meta-date {
  color: var(--blue);
}

.post-full-title {
  margin: 0;
  color: var(--darkgrey);
}

.date-divider {
  display: inline-block;
  margin: 0 6px 1px;
}

.post-full-image {
  margin: 0 auto;
  background-size: cover;
  border-radius: 5px;
  width: 100%;
  max-width: 900px;
}

.post-full-image img {
  margin: 0 auto;
  display: block;
  width: 100%;
}

@media (max-width: 1170px) {
  .post-full-image {
    margin: 0 -4vw -100px;
    height: 600px;
    border-radius: 0;
  }
}

@media (max-width: 800px) {
  .post-full-image {
    height: 400px;
  }
}

.post-full-content {
  position: relative;
  margin: 0 auto;
  padding: 70px 20px 0;
  min-height: 230px;
  max-width: 900px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 2.2rem;
  line-height: 1.6em;
  background: #fff;
}

@media (max-width: 1170px) {
  .post-full-content {
    padding: 5vw 7vw 0;
  }
}

@media (max-width: 800px) {
  .post-full-content {
    font-size: 1.9rem;
  }
}

.no-image .post-full-content {
  padding-top: 0;
}

.no-image .post-full-content:before,
.no-image .post-full-content:after {
  display: none;
}

.kg-card-markdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 920px;
}

.post-full-content h1,
.post-full-content h2,
.post-full-content h3,
.post-full-content h4,
.post-full-content h5,
.post-full-content h6,
.post-full-content p,
.post-full-content ul,
.post-full-content ol,
.post-full-content dl,
.post-full-content pre,
.post-full-content blockquote,
.post-full-comments,
.footnotes {
  min-width: 100%;
}

.post-full-content li {
  word-break: break-word;
}

.post-full-content li p {
  margin: 0;
}

.post-template .kg-card-markdown > p:first-child {
  font-size: 1.25em;
  line-height: 1.5em;
}

.post-full-content a {
  color: #000;
  box-shadow: var(--blue) 0 -1px 0 inset;
}

.post-full-content a:hover {
  color: var(--blue);
  text-decoration: none;
}

.post-full-content strong,
.post-full-content em {
  color: var(--darkgrey);
}

.post-full-content small {
  display: inline-block;
  line-height: 1.6em;
}

.post-full-content li:first-child {
  margin-top: 0;
}

.post-full-content img,
.post-full-content video {
  display: block;
  margin: 1.5em auto;
  max-width: 1040px;
  width: 100%;
}

/* Full bleed images (#full)
Super neat trick courtesy of @JoelDrapper

Usage (In Ghost edtior):

![img](/some/image.jpg#full)

*/
.post-full-content img[src$="#full"] {
  max-width: none;
  width: 100vw;
}

.post-full-content img[src$="#small"] {
  max-width: none;
  width: 300px !important;
}

/* Image captions

Usage (In Ghost editor):

![img](/some/image.jpg)
<small>Your image caption</small>

*/
.post-full-content img + br + small {
  display: block;
  margin-top: -3em;
  margin-bottom: 1.5em;
}

.post-full-content iframe {
  margin: 0 auto;
}

.post-full-content blockquote {
  margin: 0.5em 0 1.5em;
  padding: 0 1.5em;
  border-left: #3eb0ef 3px solid;
}

.post-full-content blockquote p {
  margin: 0 0 1em 0;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-style: italic;
}

.post-full-content blockquote p:last-child {
  margin-bottom: 0;
}

.post-full-content code {
  padding: 0 5px 2px;
  font-size: 0.8em;
  line-height: 1em;
  font-weight: 400 !important;
  background: var(--whitegrey);
  border-radius: 3px;
}

.post-full-content pre {
  overflow-x: auto;
  margin: 1.5em 0 3em;
  padding: 20px;
  max-width: 100%;
  border: color(var(--darkgrey) l(-10%)) 1px solid;
  color: var(--whitegrey);
  font-size: 1.4rem;
  line-height: 1.5em;
  background: color(var(--darkgrey) l(-3%));
  border-radius: 5px;
}

.post-full-content pre code {
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  background: transparent;
}

.post-full-content pre code * {
  color: inherit;
}

.post-full-content .fluid-width-video-wrapper {
  margin: 1.5em 0 3em;
}

.post-full-content hr {
  margin: 4vw 0;
}

.post-full-content hr:after {
  content: "";
  position: absolute;
  top: -15px;
  left: 50%;
  display: block;
  margin-left: -10px;
  width: 1px;
  height: 30px;
  background: color(var(--lightgrey) l(+10%));
  box-shadow: #fff 0 0 0 5px;
  transform: rotate(45deg);
}

.post-full-content h1,
.post-full-content h2,
.post-full-content h3,
.post-full-content h4,
.post-full-content h5,
.post-full-content h6 {
  color: var(--darkgrey);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.post-full-content h1 {
  margin: 0.5em 0 0.2em 0;
  font-size: 4.6rem;
  font-weight: 700;
}

@media (max-width: 500px) {
  .post-full-content h1 {
    font-size: 2.8rem;
  }
}

.post-full-content h2 {
  margin: 0.5em 0 0.2em 0;
  font-size: 3.6rem;
  font-weight: 700;
}

@media (max-width: 500px) {
  .post-full-content h2 {
    font-size: 2.6rem;
  }
}

.post-full-content h3 {
  margin: 0.5em 0 0.2em 0;
  font-size: 2.5rem;
  font-weight: 700;
}

@media (max-width: 500px) {
  .post-full-content h3 {
    font-size: 2.2rem;
  }
}

.post-full-content h4 {
  margin: 0.5em 0 0.2em 0;
  font-size: 2.8rem;
  font-weight: 700;
}

@media (max-width: 500px) {
  .post-full-content h4 {
    font-size: 2.2rem;
  }
}

.post-full-content h5 {
  display: block;
  margin: 0.5em 0;
  padding: 1em 0 1.5em;
  border: 0;
  color: var(--blue);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif, serif;
  font-size: 3.2rem;
  line-height: 1.35em;
  text-align: center;
}

@media (min-width: 1180px) {
  .post-full-content h5 {
    max-width: 1060px;
    width: 100vw;
  }
}

@media (max-width: 500px) {
  .post-full-content h5 {
    padding: 0 0 0.5em;
    font-size: 2.2rem;
  }
}

.post-full-content h6 {
  margin: 0.5em 0 0.2em 0;
  font-size: 2.3rem;
  font-weight: 700;
}

@media (max-width: 500px) {
  .post-full-content h6 {
    font-size: 2rem;
  }
}

.footnotes-sep {
  margin-bottom: 30px;
}

.footnotes {
  font-size: 1.5rem;
}

.footnotes p {
  margin: 0;
}

.footnote-backref {
  color: var(--blue) !important;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none !important;
  box-shadow: none !important;
}

/* Some grouped styles for smaller viewports */
@media (max-width: 500px) {
  .post-full-meta {
    font-size: 1.2rem;
    line-height: 1.3em;
  }

  .post-full-title {
    font-size: 2.9rem;
  }

  .post-full-image {
    margin-bottom: 4vw;
    height: 350px;
  }

  .post-full-content {
    padding: 0;
  }

  .post-full-content:before,
  .post-full-content:after {
    display: none;
  }
}

/* Tables */
.post-full-content table {
  display: inline-block;
  overflow-x: auto;
  margin: 0.5em 0 2.5em;
  max-width: 100%;
  width: auto;
  border-spacing: 0;
  border-collapse: collapse;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.6rem;
  white-space: nowrap;
  vertical-align: top;
}

.post-full-content table {
  -webkit-overflow-scrolling: touch;
  background:
    radial-gradient(
        ellipse at left,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0) 75%
      )
      0 center,
    radial-gradient(
        ellipse at right,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0) 75%
      )
      100% center;
  background-attachment: scroll, scroll;
  background-size:
    10px 100%,
    10px 100%;
  background-repeat: no-repeat;
}

.post-full-content table td:first-child {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 20px 100%;
  background-repeat: no-repeat;
}

.post-full-content table td:last-child {
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-position: 100% 0;
  background-size: 20px 100%;
  background-repeat: no-repeat;
}

.post-full-content table th {
  color: var(--darkgrey);
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.2px;
  text-align: left;
  text-transform: uppercase;
  background-color: color(var(--whitegrey) l(+4%));
}

.post-full-content table th,
.post-full-content table td {
  padding: 6px 12px;
  border: color(var(--whitegrey) l(-1%) s(-5%)) 1px solid;
}

/* 7.1. Subscribe Form
/* ---------------------------------------------------------- */

.subscribe-form {
  margin: 1.5em 0;
  padding: 6.5vw 7vw 7vw;
  border: color(var(--whitegrey) l(+2%)) 1px solid;
  text-align: center;
  background: color(var(--whitegrey) l(+4%));
  border-radius: 7px;
}

.subscribe-form-title {
  margin: 0 0 3px 0;
  padding: 0;
  color: var(--darkgrey);
  font-size: 3.5rem;
  line-height: 1;
  font-weight: 700;
}

.subscribe-form p {
  margin-bottom: 1em;
  color: var(--midgrey);
  font-size: 2.2rem;
  line-height: 1.55em;
  letter-spacing: 0.2px;
}

.subscribe-form form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 420px;
}

.subscribe-form .form-group {
  flex-grow: 1;
}

.subscribe-email {
  display: block;
  padding: 10px;
  width: 100%;
  border: color(var(--lightgrey) l(+7%)) 1px solid;
  color: var(--midgrey);
  font-size: 1.8rem;
  line-height: 1em;
  font-weight: normal;
  user-select: text;
  border-radius: 5px;
  transition: border-color 0.15s linear;

  -webkit-appearance: none;
}

.subscribe-email:focus {
  outline: 0;
  border-color: color(var(--lightgrey) l(-2%));
}

.subscribe-form button {
  display: inline-block;
  margin: 0 0 0 10px;
  padding: 0 20px;
  height: 41px;
  outline: none;
  color: #fff;
  font-size: 1.5rem;
  line-height: 37px;
  font-weight: 400;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
  background: linear-gradient(
    color(var(--blue) whiteness(+7%)),
    color(var(--blue) lightness(-7%) saturation(-10%)) 60%,
    color(var(--blue) lightness(-7%) saturation(-10%)) 90%,
    color(var(--blue) lightness(-4%) saturation(-10%))
  );
  border-radius: 5px;
  box-shadow: 0 0 0 1px inset rgba(0, 0, 0, 0.14);

  -webkit-font-smoothing: subpixel-antialiased;
}

.subscribe-form button:active,
.subscribe-form button:focus {
  background: color(var(--blue) lightness(-9%) saturation(-10%));
}

@media (max-width: 650px) {
  .subscribe-form-title {
    font-size: 2.4rem;
  }

  .subscribe-form p {
    font-size: 1.6rem;
  }
}

@media (max-width: 500px) {
  .subscribe-form form {
    flex-direction: column;
  }

  .subscribe-form .form-group {
    width: 100%;
  }

  .subscribe-form button {
    margin: 10px 0 0 0;
    width: 100%;
  }
}

/* 7.2. Post Footer
/* ---------------------------------------------------------- */

.post-full-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 3vw 0 6vw 0;
  max-width: 840px;
}

.author-card {
  display: flex;
  align-items: center;
}

.author-card .author-profile-image {
  margin-right: 15px;
  width: 60px;
  height: 60px;
}

.author-card-name {
  margin: 0 0 2px 0;
  padding: 0;
  font-size: 2rem;
}

.author-card-name a {
  color: var(--darkgrey);
  font-weight: 700;
}

.author-card-name a:hover {
  text-decoration: none;
}

.author-card-content p {
  margin: 0;
  color: var(--midgrey);
  line-height: 1.3em;
}

.post-full-footer-right {
  flex-shrink: 0;
  margin-left: 20px;
}

.author-card-button {
  display: block;
  padding: 9px 16px;
  border: color(var(--midgrey) l(+20%)) 1px solid;
  color: var(--midgrey);
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 500;
  border-radius: 20px;
  transition: all ease 0.2s;
}

.author-card-button:hover {
  border-color: var(--blue);
  color: var(--blue);
  text-decoration: none;
}

/* 7.3. Comments
/* ---------------------------------------------------------- */

.post-full-comments {
  margin: 0 auto;
  max-width: 840px;
}

/* 7.4. Related posts
/* ---------------------------------------------------------- */

.read-next-feed {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  padding: 40px 0 0 0;
}

.read-next-card {
  position: relative;
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 20px 40px;
  padding: 25px;
  color: #fff;
  background: var(--darkgrey) center center;
  background-size: cover;
  border-radius: 5px;
  box-shadow:
    rgba(39, 44, 49, 0.06) 8px 14px 38px,
    rgba(39, 44, 49, 0.03) 1px 3px 8px;
}

.read-next-card:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background: linear-gradient(
    135deg,
    rgba(0, 40, 60, 0.8) 0%,
    rgba(0, 20, 40, 0.7) 100%
  );
  border-radius: 5px;

  backdrop-filter: blur(2px);
}

.read-next-card-header {
  position: relative;
  z-index: 50;
  padding-top: 20px;
  text-align: center;
}

.read-next-card-header-sitetitle {
  display: block;
  font-size: 1.3rem;
  line-height: 1.3em;
  opacity: 0.8;
}

.read-next-card-header-title {
  margin: 0;
  padding: 0 20px;
  color: #fff;
  font-size: 3rem;
  line-height: 1.2em;
  letter-spacing: 1px;
}

.read-next-card-header-title a {
  color: #fff;
  font-weight: 300;
  text-decoration: none;
}

.read-next-card-header-title a:hover {
  text-decoration: none;
}

.read-next-divider {
  position: relative;
  display: flex;
  justify-content: center;
  height: 80px;
}

.read-next-divider svg {
  width: 40px;
  fill: transparent;
  stroke: #fff;

  stroke-width: 0.5px;
  stroke-opacity: 0.65;
}

.read-next-card-content {
  position: relative;
  z-index: 50;
  flex-grow: 1;
  display: flex;
  font-size: 1.7rem;
}

.read-next-card-content ul {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  list-style: none;
}

.read-next-card-content li {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  line-height: 1.25em;
  font-weight: 200;
  letter-spacing: -0.5px;
}

.read-next-card-content li a {
  display: block;
  padding: 20px 0;
  border-bottom: rgba(255, 255, 255, 0.3) 1px solid;
  color: #fff;
  font-weight: 500;
  vertical-align: top;
  transition: opacity 0.3s ease;
}

.read-next-card-content li:first-of-type a {
  padding-top: 10px;
}

.read-next-card-content li a:hover {
  opacity: 1;
}

.read-next-card-footer {
  position: relative;
  margin: 15px 0 3px 0;
  text-align: center;
}

.read-next-card-footer a {
  color: #fff;
}

img.limit-height {
  max-height: 500px;
  width: auto;
}

a.easy-button {
  margin: 0 auto;
  display: block;
  width: max-content;
  background-color: #145cf2;
  text-decoration: none;
  font-size: 20px;
  cursor: pointer;
  padding: 0.6em;
  color: #fff;
  border-radius: 2px;
  transition: all 0.3s;
}

a.easy-button:hover,
a.easy-button:focus {
  color: #fff;
  background-color: #133889;
}

a.easy-button:active {
  color: #fff;
  background-color: #19479a;
}

a.easy-button:visited {
  color: #fff;
}

/* 8. Author Template
/* ---------------------------------------------------------- */

.site-header-content .author-profile-image {
  z-index: 10;
  flex-shrink: 0;
  margin: 0 0 20px 0;
  width: 100px;
  height: 100px;
  box-shadow: rgba(255, 255, 255, 0.1) 0 0 0 6px;
}

.site-header-content .author-bio {
  z-index: 10;
  flex-shrink: 0;
  margin: 5px 0 10px 0;
  max-width: 600px;
  font-size: 2rem;
  line-height: 1.3em;
  font-weight: 300;
  letter-spacing: 0.5px;
  opacity: 0.8;
}

.site-header-content .author-meta {
  z-index: 10;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 10px 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: italic;
}

.site-header-content .author-location svg {
  height: 1.9rem;
  stroke: #fff;
}

.site-header-content .bull {
  display: inline-block;
  margin: 0 12px;
  opacity: 0.5;
}

.site-header-content .social-link:first-of-type {
  padding-left: 4px;
}

@media (max-width: 500px) {
  .site-header-content .author-bio {
    font-size: 1.8rem;
    line-height: 1.15em;
    letter-spacing: 0;
  }

  .author-location,
  .author-stats {
    display: none;
  }
}

/* 9. Error Template
/* ---------------------------------------------------------- */

.error-template .site-main {
  padding: 7vw 4vw;
}

.site-nav-center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.site-nav-center .site-nav-logo {
  margin-right: 0;
}

.error-message {
  text-align: center;
}

.error-code {
  margin: 0;
  font-size: 12vw;
  line-height: 1em;
  letter-spacing: -5px;
  opacity: 0.3;
}

.error-description {
  margin: 0;
  color: var(--midgrey);
  font-size: 3rem;
  line-height: 1.3em;
  font-weight: 400;
}

@media (max-width: 800px) {
  .error-description {
    margin: 5px 0 0 0;
    font-size: 1.8rem;
  }
}

.error-link {
  display: inline-block;
  margin-top: 5px;
}

.error-template .post-feed {
  padding-top: 0;
}

/* 10. Subscribe Overlay
/* ---------------------------------------------------------- */

.subscribe-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 25, 40, 0.97);
  opacity: 0;
  transition: opacity 200ms ease-in;
  pointer-events: none;

  backdrop-filter: blur(3px);
}

.subscribe-overlay:target {
  opacity: 1;
  pointer-events: auto;
}

.subscribe-overlay-content {
  position: relative;
  z-index: 9999;
  margin: 0 0 5vw 0;
  padding: 4vw;
  color: #fff;
  text-align: center;
}

.subscribe-overlay-logo {
  position: fixed;
  top: 23px;
  left: 30px;
  height: 30px;
}

.subscribe-overlay-title {
  display: inline-block;
  margin: 0 0 10px 0;
  font-size: 6rem;
  line-height: 1.15em;
}

.subscribe-overlay-description {
  margin: 0 auto 50px;
  max-width: 650px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 3rem;
  line-height: 1.3em;
  font-weight: 300;
  opacity: 0.8;
}

.subscribe-overlay form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 500px;
}

.subscribe-overlay .form-group {
  flex-grow: 1;
}

.subscribe-overlay .subscribe-email {
  display: block;
  padding: 14px 20px;
  width: 100%;
  border: none;
  color: var(--midgrey);
  font-size: 2rem;
  line-height: 1em;
  font-weight: normal;
  letter-spacing: 0.5px;
  user-select: text;
  border-radius: 8px;
  transition: border-color 0.15s linear;

  -webkit-appearance: none;
}

.subscribe-email:focus {
  outline: 0;
  border-color: color(var(--lightgrey) l(-2%));
}

.subscribe-overlay button {
  display: inline-block;
  margin: 0 0 0 15px;
  padding: 0 25px;
  height: 52px;
  outline: none;
  color: #fff;
  font-size: 1.7rem;
  line-height: 37px;
  font-weight: 400;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
  background: linear-gradient(
    color(var(--blue) whiteness(+7%)),
    color(var(--blue) lightness(-7%) saturation(-10%)) 60%,
    color(var(--blue) lightness(-7%) saturation(-10%)) 90%,
    color(var(--blue) lightness(-4%) saturation(-10%))
  );
  border-radius: 8px;
  box-shadow: 0 0 0 1px inset rgba(0, 0, 0, 0.14);

  -webkit-font-smoothing: subpixel-antialiased;
}

.subscribe-overlay button:active,
.subscribe-overlay button:focus {
  background: color(var(--blue) lightness(-9%) saturation(-10%));
}

.subscribe-overlay-close {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
}

.subscribe-overlay-close:before {
  content: "";
  position: absolute;
  top: 40px;
  right: 25px;
  display: block;
  width: 30px;
  height: 2px;
  background: #fff;
  opacity: 0.8;
  transform: rotate(45deg);
}

.subscribe-overlay-close:after {
  content: "";
  position: absolute;
  top: 40px;
  right: 25px;
  display: block;
  width: 30px;
  height: 2px;
  background: #fff;
  opacity: 0.8;
  transform: rotate(-45deg);
}

.subscribe-overlay-close:hover {
  cursor: default;
}

/* 11. Site Footer
/* ---------------------------------------------------------- */

.site-footer {
  position: relative;
  padding-top: 20px;
  padding-bottom: 60px;
  color: #fff;
  background: black;
}

.site-footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.3rem;
}

.site-footer-content a {
  color: rgba(255, 255, 255, 0.7);
}

.site-footer-content a:hover {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}

.site-footer-nav {
  display: flex;
}

.site-footer-nav a {
  position: relative;
  margin-left: 20px;
}

.site-footer-nav a:before {
  content: "";
  position: absolute;
  top: 11px;
  left: -11px;
  display: block;
  width: 2px;
  height: 2px;
  background: #fff;
  border-radius: 100%;
}

.site-footer-nav a:first-of-type:before {
  display: none;
}

@media (max-width: 650px) {
  .site-footer-content {
    flex-direction: column;
  }

  .site-footer-nav a:first-child {
    margin-left: 0;
  }
}

#table-of-contents + ul {
  border: 2px solid #eee;
  padding: 36px 100px 16px;
  border-radius: 3px;
  margin: 30px 0;
}

@media (max-width: 530px) {
  #table-of-contents + ul {
    padding: 20px 30px 10px;
    width: 100%;
  }

  #table-of-contents {
    margin-bottom: 10px;
  }
}

.main-container {
  display: flex;
}

.aside-call-to-act {
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  align-self: flex-start;
  border: 1px solid #ccc;
  padding: 20px 20px 10px;
  margin-left: 50px;
  max-width: 200px;
  margin-top: 175px;
  border-radius: 2px;
}

@media (max-width: 1000px) {
  .aside-call-to-act {
    display: none;
  }
}

.aside-call-to-act .title {
  margin-top: 0;
  font-size: 18px;
}

.inner-no-margin {
  display: flex;
}

.call-to-act-divider {
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Open Sans,
    Helvetica Neue,
    sans-serif;
  margin-bottom: 1em;
  width: calc(100% + 100px);
  padding: 20px;
  border-radius: 2px;
}

.call-to-act-divider h2,
.call-to-act-divider p {
  margin: 0;
}

.call-to-act-divider a,
.aside-call-to-act-button {
  background: #145cf2;
  color: #fff;
  border-radius: 2px;
  display: inline-block;
  padding: 0.5em 1em;
  font-size: 16px;
  border-radius: 4px;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  transition: all 0.5s;
}

.call-to-act-divider a:hover,
.aside-call-to-act-button:hover {
  text-decoration: none;
  background: #138ed6;
  transform: translateY(-2px);
}

.aside-call-to-act-button {
  display: block;
}

.lang-switcher {
  position: relative;
  margin-right: 24px;
}

.lang-switcher.active .alternative-langs {
  visibility: visible;
  opacity: 1;
  top: 115%;
}

.current-lang {
  width: 30px;
  height: 30px;
  padding: 5px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #5e5e71;
  background-color: #fff;
  text-align: center;
  border-radius: 50%;
  text-transform: uppercase;
  cursor: pointer;
}

.alternative-langs {
  visibility: hidden;
  opacity: 0;
  padding: 2px 5px;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 50%;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 4px 16px rgba(62, 62, 82, 0.2);
  transition: all 0.1s;
}

.alternative-langs::before {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  transform: rotate(45deg) translateY(-50%);
  left: 45%;
  top: 3px;
  transition: all 0.3s;
  transform-origin: top;
  pointer-events: none;
  background: #fff;
}

.alternative-langs a {
  font-weight: 500;
  font-size: 14px;
  color: #5e5e71;
  text-decoration: none;
}

.alternative-langs a + a {
  margin-top: 4px;
}

.post-full-content .post-book-image {
  height: 250px;
  border-radius: 4px;
  width: auto;
  border: 1px solid #333;
}

.footer {
  padding: 80px 0;
  margin-top: 60px;
  background: #000;
  color: #fff;
}

@media (max-width: 768px) {
  .footer {
    text-align: center;
    padding: 20px;
  }

  .footer ul {
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 20px;
  }
}

.footer .footer-title {
  max-width: 325px;
  margin-right: auto;
}

@media (max-width: 1080px) {
  .footer .footer-title {
    margin-left: auto;
  }
}

.footer .footer-title h3 {
  margin-top: 0.5em;
  font-weight: 400;
  line-height: 1.8;
}

.footer .main-footer {
  display: flex;
  gap: 50px;
  max-width: 1280px;
  margin: 0 auto;
}

@media (max-width: 1080px) {
  .footer .main-footer {
    flex-direction: column;
    align-items: center;
  }
}

.footer h3 {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  flex-basis: 24%;
}

.footer strong {
  font-size: 1em;
  color: #145cf2;
}

@media (max-width: 600px) {
  .footer .main-footer > div {
    margin-top: 30px;
  }
}

.footer ul {
  display: inline-block;
  padding: 0;
  margin-top: 10px;
  list-style: none;
}

@media (max-width: 800px) {
  .footer ul {
    margin-bottom: 20px;
    text-align: center;
  }
}

.footer ul li {
  margin-bottom: 4px;
  font-size: 20px;
}

.footer p,
.footer a {
  font-size: 14px;
  color: #bfbfbf;
}

@media (max-width: 800px) {
  .footer p,
  .footer a {
    text-align: center;
  }
}

@media (max-width: 800px) {
  .footer p {
    margin-bottom: 20px;
  }
}

.footer legend {
  display: block;
  padding: 0;
  font-weight: 600;
  font-size: 16px;
}

.footer a {
  display: block;
  padding: 7px 0;
  cursor: pointer;
}

.footer a:hover {
  color: #fff;
}

.ideas-extra-column {
  margin-top: 20px;
}

@media (max-width: 800px) {
  .ideas-extra-column {
    margin-top: -40px;
  }
}

.spaced-top {
  margin-top: 20px;
}

@media (max-width: 800px) {
  .spaced-top {
    margin-top: 0;
  }
}
